import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        margin-top: 1em;

        .measurements-editor-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .measurements-editor-header-col  {
                display: flex;
                align-items: center;

                &.col-left {
                    justify-content: flex-start;
                }
                
                &.col-right {
                    justify-content: flex-end;
                }

                .measurements-editor-header-headline {
                    font-size: 1.5em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 400;
                    margin-right: 2em;
                }

                .measurements-editor-calendar {
                    display: flex;
                    padding: .6em 1.25em;

                    .box-icon {
                        display: flex;
                        align-items: center;
                        margin-right: .75em;

                            div {
                                .icon-calendar {
                                    width: 1.5em;
                                    height: auto;
                                }
                            }
                    }
                }

                .measurements-editor-header-datepicker {
                    margin-left: 0em;
                }

                .measurements-editor-header-button {

                }
            }
        }

        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                    .form-wrapper {
                        padding: 0;
                        border: none;

                    }
                }
                .data-wrapper {
                    display: none;
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .measurements-editor-header {
                flex-wrap: wrap;
                    
                .measurements-editor-header-col {
                    &.col-left {
                        width: 100%;
                        justify-content: space-between;

                        .measurements-editor-header-headline {
                            width: 50%;
                        }
                    }

                    &.col-right {
                        width: 100%;
                        margin-top: 2em;

                        .component-button {
                            width: 100%;
                            
                            .button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    `;
